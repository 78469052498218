import React from "react"
import SEO from '@components/seo'

import Osirase from '@layouts/osirase'


export default () => {




	return(
		<Osirase>

			<SEO title="test" />

			<main id="wrapper" class="seigen">








			</main>


		</Osirase>
	)
}